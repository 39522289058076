import { useContext } from "react";
import { TextField } from "@mui/material";

import { BetStreamContext } from "../../../contexts/BetStreamContext";

const uuidRegex = /\b[\w]{8}-[\w]{4}-[\w]{4}-[\w]{4}-[\w]{12}\b/
const addressRegex = /\b0x[a-fA-F0-9]{40}\b/

const InputFilter = () => {
  const {
    setBetId,
  } = useContext(BetStreamContext);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log(event.target.value)
    if (uuidRegex.test(event.target.value)) {
      setBetId(event.target.value);
    } else if (addressRegex.test(event.target.value)) { 
      // setAddress(event.target.value);
    } else {
      // setAddress('');
      setBetId('');
    }
  }

  return (
    <TextField
      id="outlined-basic"
      variant="outlined"
      autoComplete="off"
      onChange={onChange}
      placeholder="Enter Bet Id"
      sx={{
        width: '100%',
        minWidth: '240px',
        maxWidth: '480px',
        mx: '8px',
        '& .MuiInputBase-root': {
          height: '32px',
        },
        '& .MuiOutlinedInput-root': {
          '& .MuiInputBase-input': {
            paddingX: '8px',
            paddingY: 0
          },
          '&:hover fieldset': {
            borderColor: 'primary.dark', // border color when hovered
          },
        }
      }}
      InputProps={{
        sx: {
          height: '32px',
          p: '0px'
        }
      }}
    />
  );
};

export default InputFilter;
