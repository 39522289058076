import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Button, Popover, MenuItem, TextField, Box, styled, Typography } from '@mui/material';

import { colors } from '../../../utils/colors';

const OPTIONS = [
  { min: null, max: null },
  { min: null, max: 10 },
  { min: 10, max: 100 },
  { min: 100, max: null },
]

const Inputs = ({ setAnchorEl }) => {
  const [minInputValue, setMinInputValue] = useState<string>('');
  const [maxInputValue, setMaxInputValue] = useState<string>('');

  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    name === 'minStake' ? setMinInputValue(value) : setMaxInputValue(value);
  }

  const onClick = () => {
    const min = minInputValue === '' ? null : parseFloat(parseFloat(minInputValue).toFixed(2));
    const max = maxInputValue === '' ? null : parseFloat(parseFloat(maxInputValue).toFixed(2));

    min ? searchParams.set('minStake', min.toString()) : searchParams.delete('minStake');
    max ? searchParams.set('maxStake', max.toString()) : searchParams.delete('maxStake');

    navigate({ search: searchParams.toString() });
    setAnchorEl(null);
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 1, p: '12px' }}>
      <TextField inputProps={{ min: 0 }} id='outlined-basic' autoComplete="off" type='number' variant='outlined' name='minStake' placeholder='min' value={minInputValue} onChange={onChange} />
      <TextField inputProps={{ min: 0 }} id='outlined-basic' autoComplete="off" type='number' variant='outlined' name='maxStake' placeholder='max' value={maxInputValue} onChange={onChange} />
      <Button onClick={onClick} variant='contained' sx={{ width: '100%', bgcolor: colors.pink }}>Apply</Button>
    </Box>
  )
}

const StyledMenu = styled(Popover)(({ theme }) => ({
  '& .MuiPaper-root': {
    backgroundColor: '#000',
    border: '2px solid ' + colors.pink,
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
    borderRadius: '8px',
    padding: '8px',
  },
  '& .MuiMenuItem-root': {
    color: colors.textWhite,
    margin: '4px',
    borderRadius: '4px',
    '&:hover': {
      backgroundColor: colors.pink,
    },
    
  },
}));

const StakeSizeFilter = ({ isLoading }: { isLoading: boolean }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const selectOption = (min: number | null, max: number | null) => {
    min ? searchParams.set('minStake', min.toString()) : searchParams.delete('minStake');
    max ? searchParams.set('maxStake', max.toString()) : searchParams.delete('maxStake');
    navigate({ search: searchParams.toString() });
    setAnchorEl(null);
  };

  return (
    <div style={{ margin: '0px 4px' }}>
      <Button disabled={isLoading} onClick={handleClick} sx={{
        height: '32px',
        borderRadius: '64px',
        color: "#fff",
        backgroundColor: "rgb(41, 41, 41)",
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: 1.75,
        padding: '6px 8px',
        border: 'none',
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        width: "max-content",
        textTransform: 'none',
        '&:hover': {
          backgroundColor: "rgb(41, 41, 41)",
        },
      }}>
        {!searchParams.get('minStake') && !searchParams.get('maxStake') && "All Stakes"}
        {searchParams.get('minStake') && !searchParams.get('maxStake') && `> ${searchParams.get('minStake')} MCOQ`}
        {!searchParams.get('minStake') && searchParams.get('maxStake') && `< ${searchParams.get('maxStake')} MCOQ`}
        {searchParams.get('minStake') && searchParams.get('maxStake') && `${searchParams.get('minStake')} MCOQ - ${searchParams.get('maxStake')} MCOQ`}
      </Button>
      <StyledMenu
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={()=> setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Typography sx={{ color: colors.textWhite, fontSize: '14px', fontWeight: 600, textAlign: 'center' }}>Filter By Stake Size</Typography>
        {OPTIONS.map((option, index) => (
          <MenuItem key={`stakefilter-${index}`} onClick={() => selectOption(option.min, option.max)}>
            {!option.min && !option.max && "All Stakes"}
            {option.min && !option.max && `> ${option.min} MCOQ`}
            {!option.min && option.max && `< ${option.max} MCOQ`}
            {option.min && option.max && `${option.min} MCOQ - ${option.max} MCOQ`}
          </MenuItem>
        ))}
        <Inputs setAnchorEl={setAnchorEl} />
      </StyledMenu>
    </div>
  );
}

export default StakeSizeFilter;
