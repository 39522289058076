import React, { useRef, useState } from "react";
import { DateRange } from 'react-date-range';
import { Box, Typography, Button } from "@mui/material";
import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";
import { DateTime } from "luxon";

import { BETTIMEOPTIONS } from "../../../utils/config";
import useOutsideClick from "../../../hooks/outside-click.hook";

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

const styles = {
  boldTxt: { fontSize: 12, fontWeight: 700 },
}

const FilterBetByTime = (props) => {
  const { selectedTime, selectedRange, handleRangeSelect, handleTimeSelect, handleTimeFilterChange, handleTimeRangeChange } = props;
  const [open, setOpen] = useState(false);
  const [show, setShow] = useState(false);
  const popRef = useRef()

  useOutsideClick(popRef, () => {
    setOpen(false);
    setShow(false);
  });

  return (
    <Box
      sx={{
        minWidth: 180,
        // position: "relative",
        "@media (max-width: 1024px)": {
          width: "100%"
        }
      }}
      ref={popRef}
    >
      <Button
        sx={{
          width: '100%',
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          height: 32,
          borderRadius: 16,
          backgroundColor: "primary.main",
          color: "white",
          px: 2,
          "&:hover": {
            backgroundColor: "primary.main",
          },
          textTransform: "none",
        }}
        onClick={() => {
          setOpen(!open);
          setShow(open ? false : show);
        }}
      >
        <Typography sx={styles.boldTxt}>
          {selectedTime === BETTIMEOPTIONS[4] ?
            `${DateTime.fromJSDate(selectedRange.startDate).toFormat("MMMM, dd, yyyy")} - 
                  ${selectedRange.endDate ? DateTime.fromJSDate(selectedRange.endDate).toFormat("MMMM, dd, yyyy") : ""}`
            : selectedTime
          }
        </Typography>
        {open ? <ArrowDropUp /> : <ArrowDropDown />}
      </Button>
      {open &&
        <Box
          sx={{
            mt: 1,
            backgroundColor: "rgb(148, 87, 87)",
            borderRadius: '10px',
            p: 2,
            position: "absolute",
            zIndex: 20,
            right: 0,
            maxWidth: '320px',
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: 1,
              flexWrap: "wrap",
            }}
          >
            {BETTIMEOPTIONS.map(option => (
              <Button
                key={option}
                sx={{
                  ...styles.boldTxt,
                  width: 'fit-content',
                  backgroundColor: selectedTime === option ? 'rgb(255, 82, 82)' : 'rgb(41, 41, 41)',
                  color: selectedTime === option ? 'black' : 'white',
                  borderRadius: 2,
                  height: 32,
                  textTransform: 'none',
                }}
                onClick={() => {
                  handleTimeSelect(option);
                  if (option === BETTIMEOPTIONS[4]) {
                    setShow(true);
                  } else {
                    setShow(false);
                    setOpen(false);
                    handleTimeFilterChange(option)
                  }
                }}
              >{option}</Button>
            ))}
          </Box>
          {show &&
            <DateRange
              className="date-range-picker"
              editableDateInputs={true}
              moveRangeOnFirstSelection={false}
              ranges={[selectedRange]}
              onChange={e => {
                handleRangeSelect([e.selection]);
                handleTimeRangeChange(e.selection);
              }}
            />
          }
        </Box>
      }
    </Box>
  );
};

export default FilterBetByTime;
