import { useNavigate } from "react-router-dom";
import { Box, styled, Button } from "@mui/material";

import { BETFILTEROPTIONS } from "../../../utils/config";


export const BetFilterButton = styled(Button)({
  maxWidth: '100px',
  minWidth: '70px',
  height: '32px',
  borderRadius: '64px',
  color: "#fff",
  backgroundColor: "rgb(41, 41, 41)",
  fontWeight: 600,
  fontSize: '16px',
  lineHeight: 1.75,
  padding: '6px 8px',
  border: 'none',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
  width: "100%",
  textTransform: 'none',
  '&:hover': {
    backgroundColor: "rgb(41, 41, 41)",
  },
});

type SettlementFilterProps = {
  isLoading: boolean,
}
export const SettlementFilter = ({ isLoading }: SettlementFilterProps) => {
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(window.location.search);

  const setSettlementFilter = (settlement: string) => {
    settlement !== BETFILTEROPTIONS[0] ? searchParams.set('settlement', settlement.toLocaleLowerCase()) : searchParams.delete('settlement');
    navigate({ search: searchParams.toString() });
  }

  return (
    <Box sx={{ display: 'flex', gap: '8px', width: '100%', minWidth: 'max-content', maxWidth: '540px' }}>
      {BETFILTEROPTIONS.map(option => (
        <BetFilterButton
          disabled={isLoading}
          key={option}
          onClick={() => setSettlementFilter(option)}
          sx={{
            backgroundColor: (searchParams.get('settlement') || 'all') === option.toLocaleLowerCase() ? 'rgb(255, 82, 82)' : 'rgb(41, 41, 41)',
            '&:hover': {
              backgroundColor: (searchParams.get('settlement') || 'all') === option.toLocaleLowerCase() ? 'rgb(255, 82, 82)' : 'rgb(41, 41, 41)',
            }
          }}
        >
          {option}
        </BetFilterButton>
      ))}
    </Box>
  )
} 

export default SettlementFilter