import { useRef, useState, useEffect, useContext } from "react";
import { Box, Button } from "@mui/material";

import { GameCardBack } from "../../common/GameCard";
import Hand from "./Hand";
import { CardProps } from "../../common/GameCard";

import { BlackjackContext, Card as BlackjackCardProps } from "../../../../contexts/BlackjackContext";
import { gameFieldStyles } from "../../utils";
import { colors } from "../../../../utils/colors";

import blackjack_pays from '../../../../assets/images/blackjack_pays.svg'

// temporary function there are no assets yet
const convertSuits = (cards: BlackjackCardProps[]) => {
  return cards.map((card) => {
    return {
      ...card,
      suit: (card.suit === 'S' ? '♠' : card.suit === 'H' ? '♥' : card.suit === 'D' ? '♦' : '♣') as CardProps['suit']
    } as CardProps
  })
}

const Deck = () => (
  <Box sx={{ position: 'relative', fontSize: '1.2em' }}>
    {[...new Array(5)].map((_, index) => (
      <Box key={index} sx={{
        position: 'absolute', zIndex: 0, transform: `translate(0, ${10 - index * 2}%)`, width: '5em',
        height: '7.9em',
      }}>
        <GameCardBack
          cardSx={{ width: 'inherit', height: '100%' }}
        />
      </Box>
    ))}
  </Box>
)

const GameField = ({ isTablet }: { isTablet: boolean }) => {
  const { dealerHand, playerHand, scores, gameActive, currentHandNumber } = useContext(BlackjackContext);
  const [fontSize, setFontSize] = useState(1);
  const deckRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const updateFontSize = () => {
      if (containerRef.current) {
        const maxWidth = 868;
        const containerRect = containerRef.current.getBoundingClientRect();
        if (containerRect.width >= maxWidth) {
          setFontSize(1);
        } else if (containerRect.width <= maxWidth / 2) {
          setFontSize(0.5);
        } else {
          setFontSize(containerRect.width / maxWidth);
        }
      }
    };

    updateFontSize();

    window.addEventListener("resize", updateFontSize);
    return () => {
      window.removeEventListener("resize", updateFontSize);
    };
  }, []);

  useEffect(() => { console.log(scores) }, [scores]);

  const getHandStatus = (isPlayer: boolean, scorePlayer: number, scoreDealer: number) => {
    if (gameActive) return ''
    if (!isPlayer && scores.player.length > 1) return ''
    if (scorePlayer > 21) return isPlayer ? 'lose' : 'win';
    if (scoreDealer > 21) return isPlayer ? 'win' : 'lose';
    if (scorePlayer === scoreDealer && dealerHand[0].length > 1) return 'draw';
    if (scorePlayer > scoreDealer) return isPlayer ? 'win' : 'lose';
    if (scoreDealer > scorePlayer) return isPlayer ? 'lose' : 'win';
  }


  return (
    <Box sx={{ flexGrow: { xl: 1 }, width: '100%', height: '100%' }}>
      <Box ref={containerRef} sx={{ ...gameFieldStyles, height: '100%' }}>
        <Box sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          p: isTablet ? '16px 48px' : '48px',
          borderRadius: '8px',
          backgroundColor: colors.darkGrey,
          position: 'relative',
          overflow: 'hidden',
          fontSize: `${fontSize}em`,
          height: '100%',
        }}>
          <Box ref={deckRef} sx={{ fontSize: '1.2em', width: '5em', height: '7.9em', position: 'absolute', top: 0, right: 0, zIndex: 0, transform: 'translate(-50%, -50%)' }}>
            <Deck />
          </Box>
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            position: 'relative',
            alignItems: 'center',
            justifyContent: 'space-between',
            height: '100%',
            width: '100%',
            pt: '32px',
            backgroundImage: `url(${blackjack_pays})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundSize: '40%',
          }}>
            {/* dealer hand */}
            <Hand
              hand={convertSuits(dealerHand[0])}
              deckRef={deckRef}
              score={scores.dealer[0]}
              status={getHandStatus(false, scores.player[0], scores.dealer[0])}
            />
            <Box sx={{ width: '100%', height: '0', py: {xs: '32px', md: '64px'} }}></Box>
            {/* player hand/hands(if split) */}
            <Box sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: playerHand[1].length > 0 ? 'space-between' : 'center'
            }}>
              <Hand
                hand={convertSuits(playerHand[0])}
                deckRef={deckRef}
                score={scores.player[0]}
                status={scores.player.length > 1 && currentHandNumber === 0 ? 'pick' : getHandStatus(true, scores.player[0], scores.dealer[0])}
              />
              {playerHand[1].length > 0 &&
                <Hand
                  hand={convertSuits(playerHand[1])}
                  deckRef={deckRef}
                  score={scores.player[1]}
                  status={scores.player.length > 1 && currentHandNumber === 1 && gameActive ? 'pick' : getHandStatus(true, scores.player[1], scores.dealer[0])}
                />}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default GameField;