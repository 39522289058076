import React, { useEffect, useState } from "react";
import { Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, Skeleton } from "@mui/material";
import { tableCellClasses } from "@mui/material/TableCell";
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import { colors } from "../../../utils/colors";

interface Column {
  id: string;
  label: string;
  renderCell?: (row: any) => JSX.Element | string;
}

interface TableTemplateProps {
  columns: Column[];
  rows: any[]; // rows should include Column.id as a key
  currentPage: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  isLoading: boolean;
  hasNext: boolean;
  maxRows?: number;
}

const skeletonRows = 10;

const TableTemplate: React.FC<TableTemplateProps> = ({ columns, rows, currentPage, setCurrentPage, isLoading, hasNext, maxRows=20 }) => {
  const [displayedRows, setDisplayedRows] = useState<any[]>(rows.slice(0, maxRows));

  useEffect(() => {
    if (rows.length < 1) return;
    const startIndex = (currentPage - 1) * maxRows;
    const endIndex = startIndex + maxRows;
    setDisplayedRows(rows.slice(startIndex, endIndex));
  }, [currentPage, rows, maxRows]);

  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  return (
    <Box sx={{ width: '100%', p: '8px', backgroundColor: colors.darkGrey, borderRadius: '8px' }}>
      <TableContainer component={Paper} sx={{ backgroundColor: colors.darkGrey }}>
        <Table sx={{
          [`& .${tableCellClasses.root}`]: {
            borderBottom: "none"
          }
        }}>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.label} sx={{ color: '#fff', fontWeight: 700, fontSize: '16px' }}>{column.label}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {/* Display Skeleton when isLoading is true */}
            {isLoading ? (
              Array.from({ length: skeletonRows }).map((_, index) => (
                <TableRow key={index}>
                  {columns.map((column) => (
                    <TableCell key={column.id}>
                      <Skeleton animation="wave" height={40} />
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              displayedRows.map((row, rowIndex) => (
                <TableRow key={row.id} sx={{ 
                  backgroundColor: rowIndex % 2 === 0 ? 'rgba(0, 0, 0, 0.5)' : colors.darkGrey }}>
                  {columns.map((column) => (
                    <TableCell key={column.id} sx={{ color: '#fff', p: '10px' }}>
                      {column.renderCell ? column.renderCell(row) : row[column.id]}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {/* Pagination */}
      <Box sx={{ mt: '16px', display: 'flex', justifyContent: 'right', alignItems: 'center' }}>
        <Typography sx={{ color: '#fff', mr: 2, fontWeight: 700, opacity: 0.7 }}>
          Page: {currentPage}
        </Typography>
        <IconButton
          onClick={handlePreviousPage}
          disabled={currentPage === 1 || isLoading}
          sx={{ color: '#fff' }}
        >
          <ArrowBack />
        </IconButton>
        <IconButton
          onClick={handleNextPage}
          size="large"
          disabled={isLoading || !hasNext}
          sx={{ color: '#fff' }}
        >
          <ArrowForward />
        </IconButton>
      </Box>
    </Box>
  );
};

export default TableTemplate;
