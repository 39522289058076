import React, { useContext } from 'react';
import { Box, Typography, useMediaQuery } from '@mui/material';
import gem from '../../../../assets/images/ruby_egg.svg';
import { KenoContext } from '../../../../contexts/KenoContext';
import { getMultiplier } from '../../../../utils/kenoMultipliers';
import { colors } from '../../../../utils/colors';


const Footer = ({ maxSelectedCells }: { maxSelectedCells: number }) => {
  const { winningCells, selectedCells } = useContext(KenoContext);
  const isXs = useMediaQuery('(max-width: 600px)');
  return (
    <Box sx={{ flexShrink: 0, width: '100%', marginTop: { xs: '16px', md: '40px' } }}>
      {selectedCells.length === 0 && (
        <Box sx={{ width: '100%', display: 'flex', marginTop: '8px', borderRadius: '8px', textAlign: 'center', backgroundColor: colors.grey800 }}>
          <Typography variant='body1' sx={{ color: colors.textWhite, width: '100%', paddingY: '14px', fontWeight: 600, lineHeight: 1, fontSize: '14px' }}
          >Select 1-{maxSelectedCells} numbers to play</Typography>
        </Box>
      )}
      {selectedCells.length > 0 && (
        <Box sx={{ display: 'flex', gap: { xs: '4px', md: '8px' }, }}>
          {getMultiplier("CLASSIC", selectedCells.length).map((multiplier: number, index: number) => (
            <Box sx={{
              display: 'flex',
              flexGrow: 1,
              justifyContent: 'center',
              paddingY: '12px',
              borderRadius: '8px',
              backgroundColor: winningCells.length === 10 && selectedCells.filter(item =>
                winningCells.includes(item)).length === index ? colors.darkGrey : colors.grey800
            }}
              key={`cell${multiplier}-${index}`}>
              <Typography sx={{
                color: colors.textWhite, fontWeight: 600, lineHeight: 1,
                fontSize: { xs: '12px', md: '14px' }
              }}>{multiplier}x</Typography>
            </Box>
          ))}
        </Box>
      )}
      {selectedCells.length > 0 && (
        <Box sx={{ width: '100%', display: 'flex', marginTop: '8px', borderRadius: '8px', textAlign: 'center', backgroundColor: colors.grey800 }}>
          {Array.from({ length: selectedCells.length + 1 }).map((_, index) => (
            <Box sx={{ display: 'flex', flexGrow: 1, justifyContent: 'center', paddingY: '14px', alignItems: 'center' }} key={`selectedCells${index}`}>
              <Typography sx={{
                color: colors.textWhite, fontWeight: 600, lineHeight: 1,
                fontSize: { xs: '12px', md: '14px' }
              }}>
                {index}x</Typography>
              <Box
                component={"img"}
                src={gem}
                sx={{
                  width: isXs ? '6px' : '12px',
                  marginLeft: '2px', opacity: 0.7, transition: 'opacity 0.2s ease-in-out'
                }} />
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
};

export default Footer;