import React from 'react';
import { Box, Typography } from '@mui/material';
import TokenPrice from './TokenPrice';


const PriceWidget = () => {
  return (
    <Box
      display="flex"
      justifyContent={"center"}
      flexDirection={"column"}
      alignItems="center"
      m={1}
      p='2px'
      borderRadius='7px'
      bgcolor='#2b3b45'
    >
      <Typography
          margin={0.5}
          fontWeight={700}
      >
        MCOQ PRICE
      </Typography>
      <TokenPrice />
    </Box>
  );
};

export default PriceWidget;
