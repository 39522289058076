import { Link } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';

import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import LiveHelpIcon from '@mui/icons-material/LiveHelp';

import SportsBasketballIcon from '@mui/icons-material/SportsBasketball';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import LocalBarIcon from '@mui/icons-material/LocalBar';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import AirIcon from '@mui/icons-material/Air';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import CasinoIcon from '@mui/icons-material/Casino';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';

import '@rainbow-me/rainbowkit/styles.css'

import PriceWidget from './PriceWidget/PriceWidget';
import SocialIcons from './SocialIcons';
import { UserDetailsContext } from '../../contexts/UserContext';

const drawerWidth = 165;

type SidebarProps = {
  mobileOpen: boolean,
  handleDrawerClose: () => void
  handleDrawerTransitionEnd: () => void
};

const GameLabels = [
  { to: 'games/dice', label: 'Dice', icon: <CasinoIcon color='info' />, disabled: false },
]

const SidebarLabels = [
  { to: '/', label: 'Sportsbook', icon: <SportsBasketballIcon color='info' />, disabled: false },
  { to: '/', label: 'Competitions', icon: <EmojiEventsIcon color='info' />, disabled: true },
  { to: '/statistics', label: 'Statistics', icon: <EqualizerIcon color='info' />, disabled: false },
  { to: '/betstream', label: 'Bet Activity', icon: <AirIcon color='info' />, disabled: false },

  { to: '/transactions', label: 'Profile', icon: <AccountCircleIcon color='info' />, disabled: true },
  { to: '/', label: 'VIP', icon: <LocalBarIcon color='info' />, disabled: true },
  { to: '/support', label: 'Support', icon: <LiveHelpIcon color='info' />, disabled: false },
];

const CustomListItem = ({ item, disabled, ...props }: any) => {
  return (
    <Link to={item.to}
      key={item.label}
      style={{ textDecoration: 'none', color: '#fff', pointerEvents: disabled ? 'none' : 'auto' }}
      onClick={props.onClick}
    >
      <ListItem key={item.label} disablePadding>
        <ListItemButton disabled={disabled}>
          <ListItemIcon sx={{ width: '35px', minWidth: '35px' }}>
            {item.icon}
          </ListItemIcon>
          <ListItemText primary={item.label} />
        </ListItemButton>
      </ListItem>
    </Link>
  )
}

const Games = ({ handleDrawerClose }: { handleDrawerClose: () => void }) => {
  return (
    <Accordion disableGutters sx={{
      bgcolor: 'transparent',
      boxShadow: 'none',
      p: 0,
      color: '#fff',
      '&::before': {
        display: 'none',
      },
    }}>
      <ListItem key={'Games'} disablePadding>
        <AccordionSummary
          sx={{
            maxHeight: '35px',
            p: 0
          }}
          expandIcon={
            <ExpandMoreIcon
              sx={{
                color: '#fff',
                transition: 'transform 0.3s ease-in-out',
                transform: 'rotate(0deg)',
              }}
            />
          }>
          <ListItemButton>
            <ListItemIcon sx={{ width: '35px', minWidth: '35px' }}>
              <WhatshotIcon color='info' />
            </ListItemIcon>
            <ListItemText primary={'Casino'} />
          </ListItemButton>
        </AccordionSummary>
      </ListItem>
      <AccordionDetails>
        {GameLabels.map((item, index) => (
          <CustomListItem item={item} key={item.label} onClick={handleDrawerClose}/>
        ))}
      </AccordionDetails>
    </Accordion>
  )
}

export default function Sidebar({ mobileOpen, handleDrawerClose, handleDrawerTransitionEnd }: SidebarProps) {
  const { userJWT } = useContext(UserDetailsContext);
  const [profileDisabled, setProfileDisabled] = useState(true);

  useEffect(() => {
    userJWT ? setProfileDisabled(false) : setProfileDisabled(true);
  }, [userJWT])

  const drawer = (
    <div>
      <PriceWidget />
      <List>
        {SidebarLabels.slice(0, 1).map((item, index) => (
          <CustomListItem item={item} key={item.label} onClick={handleDrawerClose} disabled={item.disabled}/>
        ))}
      </List>
      <Games handleDrawerClose={handleDrawerClose}/>
      <List>
        {SidebarLabels.slice(2, 4).map((item, index) => (
          <CustomListItem item={item} key={item.label} onClick={handleDrawerClose} disabled={item.disabled}/>
        ))}
      </List>
      <List>
        {SidebarLabels.slice(4).map((item, index) => (
          <CustomListItem item={item} key={item.label} onClick={handleDrawerClose} disabled={item.label === 'Profile' ? profileDisabled : item.disabled}/>
        ))}
      </List>
      <SocialIcons />
    </div>
  );

  return (
    <Box
      component="nav"
      sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
      aria-label="mailbox folders"
    >
      {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
      <Drawer
        container={undefined}
        variant="temporary"
        open={mobileOpen}
        onTransitionEnd={handleDrawerTransitionEnd}
        onClose={handleDrawerClose}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: 'block', sm: 'none' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
        }}
      >
        {drawer}
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: 'none', sm: 'block' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
        }}
        open
      >
        {drawer}
      </Drawer>
    </Box>
  );
}
